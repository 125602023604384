import React, { useState, useEffect, useCallback } from 'react';

const styles = {
	banner: {
		position: 'fixed',
		left: 0,
		right: 0,
		bottom: '60px',
		backgroundColor: '#f0f0f0',
		padding: '10px',
		boxSizing: 'border-box',
		zIndex: 1000,
		transition: 'height 0.3s ease-in-out',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
	title: {
		fontSize: '1rem',
		fontWeight: 'bold',
		marginBottom: '4px',
		textAlign: 'center',
	},
	description: {
		fontSize: '0.8rem',
		textAlign: 'center',
		marginBottom: '8px',
		color: 'gray',
		marginTop: '3px',
	},
	storeButtons: {
		display: 'flex',
		justifyContent: 'center',
		gap: '8px',
	},
	storeButton: {
		display: 'flex',
		alignItems: 'center',
		padding: '8px 12px',
		backgroundColor: '#007bff',
		color: 'white',
		border: 'none',
		borderRadius: '5px',
		cursor: 'pointer',
		fontSize: '0.75rem',
	},
	icon: {
		marginRight: '4px',
		width: '16px',
		height: '16px',
	},
	closeButton: {
		position: 'absolute',
		top: '5px',
		right: '5px',
		background: 'none',
		border: 'none',
		cursor: 'pointer',
		padding: '10px',
	},
	closeIcon: {
		width: '12px',
		height: '12px',
	},
};

export default function AppDownloadBanner() {
	const [isVisible, setIsVisible] = useState(true);
	const [isMobile, setIsMobile] = useState(false);
	const [isIOS, setIOS] = useState(false);
	const playstoreLink =
		'https://play.google.com/store/apps/details?id=com.kalyanisuperapp';

	const iosLink =
		'https://apps.apple.com/in/app/kalyani-motors-app/id6479185557';

	useEffect(() => {
		const userAgent = navigator.userAgent;

		// Check if the device is an iPhone or iPad
		if (/iPhone|iPad|iPod/i.test(userAgent)) {
			setIOS(true);
		}
	}, []);

	const checkDeviceType = useCallback(() => {
		const userAgent = navigator.userAgent;
		const mobileRegex =
			/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
		const isMobileDevice = mobileRegex.test(userAgent.toLowerCase());
		const isScreenWidthMobile = window.innerWidth < 768; // Check screen width

		setIsMobile(isMobileDevice && isScreenWidthMobile);
	}, []);

	useEffect(() => {
		checkDeviceType(); // Initial check

		const handleResize = () => {
			clearTimeout(window.resizeTimeout);
			window.resizeTimeout = setTimeout(checkDeviceType, 150);
		};

		window.addEventListener('resize', handleResize);
		return () => {
			clearTimeout(window.resizeTimeout);
			window.removeEventListener('resize', handleResize);
		};
	}, [checkDeviceType]);

	const bannerStyle = {
		...styles.banner,
		height: '15vh',
		display: isVisible ? 'block' : 'none',
		backgroundColor: '#EAF2F8',
		boxShadow: '0 -4px 10px rgba(0, 0, 0, 0.2)',
	};

	const handleClose = () => {
		setIsVisible(false);
	};

	if (!isMobile) return null;

	return (
		<div style={bannerStyle}>
			<button style={styles.closeButton} onClick={handleClose}>
				<svg style={styles.closeIcon} viewBox="0 0 24 24" fill="currentColor">
					<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
				</svg>
			</button>
			<div style={styles.content}>
				<h2 style={styles.title}>Get a Better Experience!</h2>
				<p style={styles.description}>
					Download or open our app now for a seamless mobile experience.
				</p>
				<div style={styles.storeButtons}>
					<a
						style={{ textDecoration: 'none' }}
						href={isIOS ? iosLink : playstoreLink}
					>
						<button style={styles.storeButton}>
							<svg style={styles.icon} viewBox="0 0 24 24" fill="currentColor">
								<path d="M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14zm-4.2-5.78v1.75l3.2-2.99L12.8 9v1.7c-3.11.43-4.35 2.56-4.8 4.7 1.11-1.5 2.58-2.18 4.8-2.18z" />
							</svg>
							Open Now
						</button>
					</a>
				</div>
			</div>
		</div>
	);
}
